class CommonUtils {
  parseToParams(param) {
    // 将对象转化成 get/delete 方法的参数形式
    let obj = param || {},
      str = "";
    for (let [k, v] of Object.entries(obj)) {
      str += k + "=" + v + "&";
    }

    return str.substring(0, str.length - 1);
  }
  getFileMimeType(file) {
    // 校验文件格式
    const map = {
      // TODO 待完善 (注 .docx 暂有bug: 当文件为新建的空文件时，识别不出文件格式)
      D0CF11E0: "xls,doc,ppt",
      "255044462D312E": "pdf",
      FFD8FFE0: "jpg",
      "89504E47": "png",
      47494638: "gif",
      52494646: "webp",
      "4D5A900003": "exe, dll, drv, vxd, sys, ocx, vbx",
      "3C3F786D6C": "xml",
      "68746D6C3E": "html",
      41564920: "avi",
      57415645: "wav",
      "2E7261FD": "ram",
      "6D6F6F76": "mov",
      "504B0304": "pptx,xlsx,docx",
    };

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        try {
          let array = new Uint8Array(event.target.result);
          array = array.slice(0, 4);
          let arr = [...array];
          let key = arr
            .map((item) => item.toString(16).toUpperCase().padStart(2, "0"))
            .join("");
          resolve(map[key]);
        } catch (e) {
          reject(e);
        }
      };
    });
  }
}

export default new CommonUtils();

import { Message, MessageBox } from "element-ui";

export const createIdsRequest =
  (msg, ...arg) =>
  (request, ids, ...args) => {
    return new Promise((resolve, reject) => {
      if (!request || !ids) {
        Message.warning("参数错误");
        reject();
        return;
      }
      if (!Array.isArray(ids) || !ids.length) {
        Message.warning("至少选择一条数据");
        reject();
        return;
      }
      MessageBox.confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            ids,
          }).then(() => {
            resolve();
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

export const deleteRequest = createIdsRequest("确定要删除当前数据吗？");

export const arr2tree = (arr, parentId, idKey = "id") => {
  const obj = {};
  const result = [];
  // 将数组中数据转为键值对结构 (这里的数组和obj会相互引用)
  arr.map((el) => {
    obj[el[idKey]] = el;
  });
  for (let i = 0, len = arr.length; i < len; i++) {
    const id = arr[i].parentId;
    if (id === parentId || (id !== 0 && !id)) {
      result.push(arr[i]);
      continue;
    }
    if (!obj[id]) {
      arr[i].children = [];
      result.push(arr[i]);
      continue;
      // throw new Error('undefined menuId')
    }
    if (obj[id] && obj[id].children) {
      obj[id].children.push(arr[i]);
    } else {
      obj[id].children = [];
      obj[id].children.push(arr[i]);
    }
  }
  return result;
};

export const tree2arr = (tree = []) => {
  let ret = [];
  tree.forEach((e) => {
    ret.push(e);
    if (e.children && e.children.length) {
      ret = [...ret, ...tree2arr(e.children)];
    }
  });
  return ret;
};

export const formateTreeChildren = (tree) => {
  tree.forEach((item) => {
    if (item.children && item.children.length) {
      item.children = formateTreeChildren(item.children);
    } else {
      delete item.children;
    }
  });
  return tree;
};
