<template>
  <div class="question-store-type container">
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        size="medium"
        prefix-icon="el-icon-search"
        name="typeName"
        placeholder="请输入分类名称"
      ></YField>
      <YButton do="search" class="btn btn_blue"></YButton>
      <YButton class="btn btn_blue" @click="add">
        <i class="el-icon-plus el-icon--left" />
        新建分类
      </YButton>

      <div class="operate">
        <el-link
          type="info"
          underline
          @click="deleteQuestionStoreType()"
          class="mr8"
        >
          <i class="el-icon-delete"></i>
          删除分类
        </el-link>
      </div>

      <YQueryTable
        ref="queryTable"
        :serve="getList"
        class="table"
        tooltip-effect="dark"
        row-key="id"
        :tree-props="{ children: 'children' }"
        default-expand-all
        @selection-change="handleSelection"
      >
        <template>
          <el-table-column type="selection" />
          <el-table-column
            label="分类名称"
            prop="typeName"
            show-overflow-tooltip
          />
          <el-table-column
            label="上级分类"
            prop="parentName"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="创建人"
            prop="userName"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="创建时间"
            prop="createTime"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="修改时间"
            prop="updateTime"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column label="操作" prop="name" min-width="240">
            <template slot-scope="scope">
              <el-link class="mr8" @click="edit(scope.row)">编辑</el-link>
              <el-link
                @click="deleteQuestionStoreType([scope.row.id])"
                type="danger"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>

    <el-dialog
      width="450px"
      class="dialog_wrapper"
      :title="isEdit ? '编辑题库分类' : '新建题库分类'"
      :visible.sync="dialogShow"
    >
      <YForm
        v-model="formValues"
        ref="addForm"
        label-position="top"
        @submit="saveForm"
      >
        <YField
          label="分类名称"
          name="typeName"
          :maxlength="15"
          show-word-limit
          :rules="['required', 'whiteSpace']"
        />
        <YField
          label="上级分类"
          name="parentId"
          component="el-cascader"
          :options="typeTree"
          :props="{
            label: 'typeName',
            checkStrictly: true,
            emitPath: false,
            value: 'id',
          }"
        />
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            type="default"
            @click="dialogShow = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchQuestionStoreTypeList,
  saveQuestionStoreType,
  deleteQuestionStoreType,
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import { deleteRequest } from "@/utils/index.js";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import { arr2tree } from "@/utils/index.js";

export default {
  data() {
    return {
      tableForm: {},
      formValues: {},
      typeList: [],
      typeTree: [],
      selectedRows: [],
      isEdit: false,
      dialogShow: false,
    };
  },
  components: { headerBar },
  methods: {
    clearValidate(dom) {
      dom = dom || this.$refs.addForm;
      dom && this.$nextTick(dom.clearValidate);
    },

    async deleteQuestionStoreType(ids) {
      ids = ids || this.selectedRows.map((e) => e.id);
      await deleteRequest(deleteQuestionStoreType, ids);
      this.$refs.queryTable.runServe();
    },
    add() {
      this.formValues = {};
      this.clearValidate();
      this.isEdit = false;
      this.dialogShow = true;
    },

    async edit(row) {
      try {
        this.isEdit = true;
        this.formValues = {
          id: row.id,
          typeName: row.typeName,
          parentId: row.parentId,
        };
        this.clearValidate();
        this.dialogShow = true;
      } catch (e) {
        this.$message.error(e?.message);
        this.dialogShow = false;
      }
    },

    async saveForm() {
      try {
        const { parentId } = this.formValues;
        this.formValues.parentName = this.typeList.find(
          (e) => e.id === parentId
        )?.typeName;
        await saveQuestionStoreType({
          ...this.formValues,
        });
        this.$refs.queryTable.runServe({ currentPage: 1 });
      } finally {
        this.dialogShow = false;
      }
    },

    handleSelection(rows) {
      this.selectedRows = rows;
    },

    async getList({ formValues }) {
      const { data } = await fetchQuestionStoreTypeList(formValues);
      this.typeList = data;
      const tree = arr2tree(data);
      this.typeTree = tree;
      return {
        data: tree,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.operate {
  margin-bottom: 12px;
  text-align: right;
  .el-icon-delete {
    color: #f65160;
  }
}
</style>
