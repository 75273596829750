import http from '@/plugins/http.js'
const {post, axios} = http
// 考试列表
export const fetchExamList = data => {
  return post('/exam/api/exam/exam/paging', data)
}
// 题库列表
export const fetchQuestionStoreList = data => {
  return post('/exam/api/repo/paging', data)
}
// 题库列表
export const questionImport = (file, data) => {
  const formData = new FormData()
  formData.append('file', file)
  data && Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return axios.request(
    {
      method: 'post',
      url: '/exam/api/qu/qu/import',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }
  )
}
// 考试状态
export const setExamState = data => {
  return post('/exam/api/exam/exam/state', data)
}
// 考试审核
export const checkExam = data => {
  return post('/exam/api/exam/exam/check', data)
}
// 获取所有题库
export const fetchAllQuestionStore = (data = {}) => {
  return post('/exam/api/repo/list', data)
}
// 考试删除
export const deleteExam = data => {
  return post('/exam/api/exam/exam/delete', data)
}
// 考试新建/编辑
export const saveExam = data => {
  return post('/exam/api/exam/exam/save', data)
}
// 考试详情
export const fetchExamDetail = data => {
  return post('/exam/api/exam/exam/detail', data)
}
// 题库新建/编辑
export const saveQuestionStore = data => {
  return post('/exam/api/repo/save', data)
}
export const deleteQuestionStore = data => {
  return post('/exam/api/repo/delete', data)
}
// 题目列表
export const fetchQuestionList = data => {
  return post('/exam/api/qu/qu/paging', data)
}
// 题目新建/编辑
export const saveQuestion = data => {
  return post('/exam/api/qu/qu/save', data)
}
// 题目详情
export const fetchQuestionDetail = data => {
  return post('/exam/api/qu/qu/detail', data)
}
// 题目删除
export const deleteQuestion = data => {
  return post('/exam/api/qu/qu/delete', data)
}
// 试题模板
export const getQustionTemp = async () => {
  return axios.request({
    url: '/exam/api/qu/qu/import/template',
    method: 'post',
    data: {},
    timeout: 200000,
    responseType: 'blob'
  })
}
// 分类列表
export const fetchQuestionStoreTypeList = data => {
  return post('/exam/api/repoType/list', data)
}
// 分类新建/编辑
export const saveQuestionStoreType = data => {
  return post('/exam/api/repoType/save', data)
}
// 分类删除
export const deleteQuestionStoreType = data => {
  return post('/exam/api/repoType/delete', data)
}
// 考试统计
export const fetchExamStatistic = data => {
  return post('/exam/api/exam/exam/count', data)
}
// 问卷列表
export const fetchQuestionaireList = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/list',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
export const questionSurvey = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/listUnBind',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷新建
export const addQuestionaire = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/save',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷编辑
export const updateQuestionaire = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/update',
    method: 'post',
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷删除
export const deleteQuestionaire = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/delete',
    method: 'post',
    data
  })
}
// 问卷详情
export function fetchQuestionaireInfo(data) {
  return axios.request({
    url: '/exam/api/questionSurvey/info',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      needToast: false
    },
    data: JSON.stringify(data)
  })
}
// 问卷绑定详情
export const fetchQuestionaireBindedVideo = (data = {}) => {
  return axios.request({
    url: '/exam/api/course/getSurveyCourseById',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷绑定
export const bindQuestionaire = (data = {}) => {
  return axios.request({
    url: '/exam/api/course/bind',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷发布查询
export const queryQuestionairePublic = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/queryOtherPublic',
    method: 'get',
    params: {
      timeStamp: Date.now(),
      ...data
    }
  })
}
// 问卷发布
export const questionairePublic = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/editSurveyState',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      timeStamp: Date.now(),
      ...data
    }
  })
}
// 问卷状态
export const questionaireState = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/updateOthPubState',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      timeStamp: Date.now(),
      ...data
    }
  })
}
// 问卷题目列表
export const fetchQuestionaireListById = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/list',
    method: 'get',
    headers: {
      needToast: false
    },
    params: {
      timeStamp: Date.now(),
      ...data
    }
  })
}
// 问卷问题删除
export const deleteQuestionaireField = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/delete',
    method: 'get',
    params: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷问题新建
export const addQuestionaireField = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/save',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷问题编辑
export const updateQuestionaireField = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/update',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 问卷问题编辑
export const fetchSelectQuestionStatistic = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/countChooseAnswers',
    method: 'get',
    headers: {
      needToast: false
    },
    params: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
//提交问卷
export const submitQuestionStatistic = (data = {}) => {
  return axios.request({
    url: '/exam/api/questionSurvey/answerSubmit',
    method: 'post',
    headers: {
      needToast: false
    },
    data,
  })
}
//检查问卷是否提交
export const checkSubmit = (data) => {
  return axios.request({
    url: `/exam/api/questionSurvey/checkSubmit/${data}`,
    method: 'get',
  })
}
// 填空题统计
export const fetchinputQuestionStatistic = (data = {}) => {
  return axios.request({
    url: '/exam/api/question/countInputAnswers',
    method: 'post',
    headers: {
      needToast: false
    },
    data: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 获取学科列表
export const fetchMeaicalList = (data = {}) => {
  return axios.request({
    url: '/medical-list',
    method: 'get',
    params: {
      ...data,
      timeStamp: Date.now()
    }
  })
}
// 获取课程列表
export const fetchAllCourse = () => {
  return axios.request({
    url: '/course/all',
    method: 'get',
  })
}
// 课程绑定
export const updateCourse = (data) => {
  return axios.request({
    url: '/course',
    method: 'put',
    data
  })
}

